import { BsGlobe } from "react-icons/bs";
import eventBus from "../common/eventBus";
import { EuiBadge } from "@elastic/eui";

const countryNames = {
  CM: "Cameroon",
  GA: "Gabon",
  CI: "Ivory cost",
  SN: "Senegal",
  NG: "Nigeria",
  SL: "Sierra Leone",
  EG: "Egypt",
  KE: "Kenya",
  TZ: "Tanzania",
  UG: "Uganda",
  GH: "Ghana",
  BF: "Burkina faso",
  ZM: "Zambia",
  RW: "Rwanda",
};

// Iterate over the keys and call the function

const countryFlags = {};
// See https://flagpedia.net/download/api for country flags img
Object.keys(countryNames).map((country) => {
  countryFlags[
    country
  ] = `https://flagcdn.com/w20/${country.toLowerCase()}.png`;
});

const getCountryFlagByCode = (code) => {
  return `https://flagcdn.com/w20/${code.toLowerCase()}.png`;
};

export function getCountryCode(countryName) {
  return "";
}

const countryCodes = () => {
  const codeList = {};

  for (let key in countryNames) {
    const value = countryNames[key];
    codeList[value] = key;
  }

  return codeList;
};

const countryWithFlag = {};
Object.keys(countryNames).map((country) => {
  countryWithFlag[country] = (
    <>
      <img
        src={countryFlags[country]}
        width="16"
        height="12"
        alt={countryNames[country]}
        style={{ marginRight: "5px" }}
      />{" "}
      {countryNames[country]}{" "}
    </>
  );
});

countryWithFlag["ALL"] = <> All </>;
countryWithFlag["WORLD"] = (
  <>
    <BsGlobe size={16} style={{ marginRight: "5px" }} /> World
  </>
);

const pspLogos = {
  CM_MTNMOMO:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  CM_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  BF_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  BF_MOBICASH:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mobicash.png",
  CI_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  CI_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  CI_MOOV:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/moov.png",
  GA_MOOV:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/moov.png",
  GA_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  GH_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  GH_TIGO:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/tigo.png",
  GH_VODAFONE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/vodafone.png",
  KE_MPESA:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mpesa.png",
  RW_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  RW_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  SN_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  SN_FREEMONEY:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/free-money.png",
  SN_EMONEY:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/e-money.png",
  SL_ORANGE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/orange.png",
  TZ_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  TZ_TIGO:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/tigo.png",
  TZ_HALOPESA:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/halopesa.png",
  TZ_VODAFONE:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/vodafone.png",
  UG_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  UG_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  ZM_AIRTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/airtel.png",
  ZM_MTN:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/mtn.png",
  ZM_ZAMTEL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/zamtel.png",
  WORLD_PAYPAL:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/paypal.png",
  WORLD_VISA:
    "https://payunit-paymentlinks-imgs.s3.us-west-2.amazonaws.com/psps/cb.png",
};

const pspWithLogo = {
  CM_MTNMOMO: (
    <>
      <img
        src={pspLogos["CM_MTNMOMO"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="MTN"
      />{" "}
      MTN MOMO
    </>
  ),
  CM_ORANGE: (
    <>
      <img
        src={pspLogos["CM_ORANGE"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Orange"
      />{" "}
      ORANGE MONEY{" "}
    </>
  ),
  BF_ORANGE: (
    <>
      <img
        src={pspLogos["BF_ORANGE"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Ornage BF"
      />{" "}
      ORANGE MONEY BF'{" "}
    </>
  ),
  BF_MOBICASH: (
    <>
      <img
        src={pspLogos["BF_MOBICASH"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="MOBICASH BF"
      />{" "}
      MOBICASH BF
    </>
  ),
  CI_MTN: (
    <>
      <img
        src={pspLogos["CI_MTN"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="MTN"
      />{" "}
      MTN MOMO
    </>
  ),
  CI_ORANGE: (
    <>
      <img
        src={pspLogos["CI_ORANGE"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Orange"
      />{" "}
      ORANGE MONEY{" "}
    </>
  ),
  CI_MOOV: (
    <>
      <img
        src={pspLogos["CI_MOOV"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Moov"
      />{" "}
      MOOV MONEY
    </>
  ),
  GA_MOOV: (
    <>
      <img
        src={pspLogos["GA_MOOV"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Moov"
      />{" "}
      MOOV MONEY
    </>
  ),
  GA_AIRTEL: (
    <>
      <img
        src={pspLogos["GA_AIRTEL"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Airtel"
      />{" "}
      AIRTEL MONEY{" "}
    </>
  ),
  GH_VODAFONE: (
    <>
      <img
        src={pspLogos["GH_VODAFONE"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="VODAFONE MONEY GH"
      />{" "}
      VODAFONE MONEY GH
    </>
  ),
  GH_MTN: (
    <>
      <img
        src={pspLogos["GH_MTN"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="MTN MOMO GH"
      />{" "}
      MTN MOMO GH
    </>
  ),
  GH_TIGO: (
    <>
      <img
        src={pspLogos["GH_TIGO"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="TIGO MONEY GH"
      />{" "}
      TIGO MONEY GH
    </>
  ),
  KE_MPESA: (
    <>
      <img
        src={pspLogos["KE_MPESA"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Mpesa KE"
      />{" "}
      MPESA KE
    </>
  ),
  RW_AIRTEL: (
    <>
      <img
        src={pspLogos["RW_AIRTEL"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="RW_AIRTEL"
      />{" "}
      AIRTEL MONEY RW
    </>
  ),
  RW_MTN: (
    <>
      <img
        src={pspLogos["RW_MTN"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="RW_MTN"
      />{" "}
      MTN MONEY RW
    </>
  ),
  SN_ORANGE: (
    <>
      <img
        src={pspLogos["SN_ORANGE"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Orange"
      />{" "}
      ORANGE MONEY{" "}
    </>
  ),
  SN_FREEMONEY: (
    <>
      <img
        src={pspLogos["SN_FREEMONEY"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="FreeMoney"
      />{" "}
      FREE MONEY{" "}
    </>
  ),
  SN_EMONEY: (
    <>
      <img
        src={pspLogos["SN_EMONEY"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="FreeMoney"
      />{" "}
      E MONEY{" "}
    </>
  ),
  SL_ORANGE: (
    <>
      <img
        src={pspLogos["SL_ORANGE"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Orange"
      />{" "}
      ORANGE MONEY{" "}
    </>
  ),
  TZ_AIRTEL: (
    <>
      <img
        src={pspLogos["TZ_AIRTEL"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="AIRTEL MONEY TZ"
      />{" "}
      AIRTEL MONEY TZ
    </>
  ),
  TZ_TIGO: (
    <>
      <img
        src={pspLogos["TZ_TIGO"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="TIGO MONEY TZ"
      />{" "}
      TIGO MONEY TZ
    </>
  ),
  TZ_HALOPESA: (
    <>
      <img
        src={pspLogos["TZ_HALOPESA"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="HALOPESA MONEY TZ"
      />{" "}
      HALOPESA MONEY TZ
    </>
  ),
  TZ_VODAFONE: (
    <>
      <img
        src={pspLogos["TZ_VODAFONE"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="VODAFONE MONEY TZ"
      />{" "}
      VODAFONE MONEY TZ
    </>
  ),
  UG_AIRTEL: (
    <>
      <img
        src={pspLogos["UG_AIRTEL"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="AIRTEL MONEY UG"
      />{" "}
      AIRTEL MONEY UG
    </>
  ),
  UG_MTN: (
    <>
      <img
        src={pspLogos["UG_MTN"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="MTN MOMO UG"
      />{" "}
      MTN MOMO UG
    </>
  ),
  ZM_AIRTEL: (
    <>
      <img
        src={pspLogos["ZM_AIRTEL"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="AIRTEL MONEY ZM"
      />{" "}
      AIRTEL MONEY ZM
    </>
  ),
  ZM_MTN: (
    <>
      <img
        src={pspLogos["ZM_MTN"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="MTN MOMO ZM"
      />{" "}
      MTN MOMO ZM
    </>
  ),
  ZM_ZAMTEL: (
    <>
      <img
        src={pspLogos["ZM_ZAMTEL"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="ZAMTEL MONEY ZM"
      />{" "}
      ZAMTEL MONEY ZM
    </>
  ),
  WORLD_PAYPAL: (
    <>
      <img
        src={pspLogos["WORLD_PAYPAL"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Paypal"
      />{" "}
      PAYPAL{" "}
    </>
  ),
  WORLD_VISA: (
    <>
      <img
        src={pspLogos["WORLD_VISA"]}
        width="25"
        style={{ marginRight: "5px" }}
        alt="Visa"
      />{" "}
      VISA{" "}
    </>
  ),
};
const getCountryValues = () => {
  const countries = Object.keys(countryNames).map((country) => {
    return {
      label: (
        <>
          <img
            src={countryFlags[country]}
            width="16"
            height="12"
            alt={countryNames[country]}
          />{" "}
          {countryNames[country]}
        </>
      ),
      value: country,
    };
  });

  countries.push({
    label: (
      <>
        <BsGlobe size={16} /> World
      </>
    ),
    value: "WORLD",
  });

  return countries;
};

const countryValues = getCountryValues();

const kycCountryValues = countryValues.filter((country) => {
  return country.value !== "WORLD";
});

const optionsProvider = Object.keys(pspLogos).map((psp) => {
  return {
    value: psp,
    inputDisplay: (
      <>
        <div>
          <img
            width={20}
            src={pspLogos[psp]}
            style={{ marginRight: "5px", display: "inline" }}
            alt={psp}
          />
          <span style={{ display: "inline" }}>{psp}</span>
        </div>
      </>
    ),
  };
});

const optionsProviderWithoutAll = optionsProvider;

optionsProvider.push({
  value: "All",
  inputDisplay: "All providers",
});

const currencyList = [
  "XAF",
  "USD",
  "XOF",
  "EUR",
  "NGN",
  "EGP",
  "MAD",
  "MZN",
  "SLE",
  "ZAR",
  "KES",
  "TZS",
  "UGX",
  "GHS",
  "ZMW",
  "RWF",
];
export const optionsSelect = {
  value: "",
  text: "--- select ---",
};

export const countryCurrencies = [
  { currency: "XAF", countryCode: "CM", countryName: "Cameroon" },
  { currency: "XAF", countryCode: "GA", countryName: "Gabon" },
  { currency: "XOF", countryCode: "CI", countryName: "Ivory Coast" },
  { currency: "XOF", countryCode: "SN", countryName: "Senegal" },
  { currency: "NGN", countryCode: "NG", countryName: "Nigeria" },
  { currency: "SLE", countryCode: "SL", countryName: "Sierra Leone" },
  { currency: "EGP", countryCode: "EG", countryName: "Egypt" },
  { currency: "KES", countryCode: "KE", countryName: "Kenya" },
  { currency: "TZS", countryCode: "TZ", countryName: "Tanzania" },
  { currency: "UGX", countryCode: "UG", countryName: "Uganda" },
  { currency: "GHS", countryCode: "GH", countryName: "Ghana" },
  { currency: "XOF", countryCode: "BF", countryName: "Burkina Faso" },
  { currency: "ZMW", countryCode: "ZM", countryName: "Zambia" },
  { currency: "RWF", countryCode: "RW", countryName: "Rwanda" },
];

const optionsCurrencies = currencyList.map((currency) => {
  return {
    value: currency,
    inputDisplay: currency,
  };
});
optionsCurrencies.push({ value: "All", inputDisplay: "All currencies" });

const docTypes = {
  selfie: "Selfie",
  certificate_of_negotiation: "Certificate of negotiation",
  tax_declaration: "Tax declaration",
  passport_or_id_card: "Passport or ID card",
  non_conviction: "Non conviction",
};

const errorHandler = (error, toToast = true) => {
  console.log("ERROR", error);

  const errorList = {
    ERR_NETWORK: "Network error, please check your internet.",
    ERR_BAD_REQUEST:
      "An error occured. Please check your informations and try again.",
    ERR_BAD_RESPONSE: "Request failed: Server error. please try again.",
    ECONNABORTED:
      "Error due to request timeout. Please check your internet and try again.",
    ENOTFOUND: "Request failed: Server not found. Please try again.",
    ECONNREFUSED: "Request failed: Access denied to the server.",
    ETIMEDOUT:
      "Error due to request timeout. Please check your internet and try again.",
    EHOSTUNREACH: "Request failed: Server unreachable. Please try again.",
    EPIPE: "Request failed: Your internet is too slow or not working.",
    EAI_AGAIN: "Request failed: DNS problem. Please try again.",
    EPROTO: "Request failed: Protocol error. Please try again.",
    ENETUNREACH:
      "Request failed: Network is unreachable. Please check your internet.",
    ERR_CONNECTION_TIMED_OUT:
      "Request failed: The server took too long to respond. Please try again.",
    ERR_CONNECTION_REFUSED:
      "Request failed: The server refused to connect. Please try again.",
    ERR_ADDRESS_UNREACHABLE: "Request failed: Server address unreachable.",
    ERR_INTERNET_DISCONNECTED:
      "Request failed: Internet connection was lost while processing. Please try again.",
    ERR_PROXY_CONNECTION_FAILED:
      "Request failed: The connection to the proxy server failed. Please try again.",
    ERR_NAME_RESOLUTION_FAILED: "Request failed: Domain name error.",
  };

  const truncatedMessage =
    error?.response?.data?.message?.split("Internal Server Error:") ?? "";

  const message =
    (truncatedMessage[0] || truncatedMessage[1]) ??
    errorList[error?.code] ??
    "An error occured. please try again";

  if (toToast) {
    eventBus.dispatch("toast", {
      title: "Oops Error",
      color: "danger",
      text: <p>{message}</p>,
    });
  }

  return message;
};

const kycStatus = {
  PENDING: <EuiBadge color="warning">Pending</EuiBadge>,
  VERIFIED: <EuiBadge color="success">Verified</EuiBadge>,
  VALIDATED: <EuiBadge color="success">Validated</EuiBadge>,
  REJECTED: <EuiBadge color="danger">Rejected</EuiBadge>,
};

const activeStatus = {
  ACTIVE: <EuiBadge color="success"> Active </EuiBadge>,
  INACTIVE: (
    <EuiBadge color="warning" >
      {" "}
      Inactive{" "}
    </EuiBadge>
  ),
  SUSPENDED: (
    <EuiBadge color="danger" >
      {" "}
      Suspended{" "}
    </EuiBadge>
  ),
  DISABLED: (
    <EuiBadge color="rgb(189, 39, 30)" >
      {" "}
      Inactive{" "}
    </EuiBadge>
  ),
};

const getUniqueOptions = (arr, field) => {
  if (arr?.length) return;
  const uniqueOptions = [...new Set(arr?.map((item) => item?.[field]))];
  return uniqueOptions;
};

export {
  countryWithFlag,
  errorHandler,
  activeStatus,
  countryValues,
  pspLogos,
  getCountryFlagByCode,
  kycCountryValues,
  pspWithLogo,
  countryFlags,
  optionsProvider,
  optionsCurrencies,
  currencyList,
  countryNames,
  docTypes,
  countryCodes,
  optionsProviderWithoutAll,
  kycStatus,
};

import React from 'react';
import {
    EuiPanel,
} from '@elastic/eui';
import KycList from "./kycList";

const WalletKYCPage = () => {
    return (
        <EuiPanel paddingSize="m" style={{marginTop:"4em"}} hasShadow={false} hasBorder={true}>
            <KycList/>
        </EuiPanel>
    );
};

export default WalletKYCPage;

import React, { useState } from 'react';
import {
    EuiPageTemplate,
    EuiIcon,
    EuiConfirmModal,
} from '@elastic/eui';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNav from "./sideNav";
import { errorHandler } from '../../utils/tools';

const WalletPage = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalAppVisible, setIsModalAppVisible] = useState(false);
    const [appStatus, setAppStatus] = useState('');
    const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
    const [isPopoverOpen, setPopover] = useState(false);
    const [showBottomBar, setshowBottomBar] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const navigate = useNavigate()

    const titleRoutes = {
        wallet: 'Wallet',
        kycs: 'Wallet KYCs',
    }

    const closePopover = () => {
        setPopover(false);
    };

    const closeDestroyModal = () => setIsDestroyModalVisible(false);
    const closeAppModal = () => setIsModalAppVisible(false);

    const closeModal = () => {
        setIsModalVisible(false);
        closePopover()
    }

    const changeUserStatus = async (action) => {
        setLoading(true)
        try {
            setLoading(false)

            closeModal()
            closeDestroyModal()
        } catch (err) {
            closeModal()
            closeDestroyModal()
            setLoading(false)
            errorHandler(err)
        }
    }

    let modal
    if (isModalVisible) {
        modal = (
            <EuiConfirmModal
                title="Activate Partner"
                onCancel={closeModal}
                onConfirm={() => changeUserStatus("ACTIVE")}
                cancelButtonText="Cancel"
                confirmButtonText="Activate"
                isLoading={loading}
            // defaultFocusedButton="confirm"
            >
                <p>Are you sure you want to activate this partner?</p>
            </EuiConfirmModal>
        );
    }

    let destroyModal;

    if (isDestroyModalVisible) {
        destroyModal = (
            <EuiConfirmModal
                title="Deactivate Partner"
                onCancel={closeDestroyModal}
                onConfirm={() => changeUserStatus("INACTIVE")}
                cancelButtonText="Cancel"
                confirmButtonText="Deactivate"
                buttonColor="danger"
                isLoading={loading}
            >
                <p>Are you sure you want to deactivate this partner?</p>
            </EuiConfirmModal>
        );
    }

    let appModal;
    let statusMessage;

    if (appStatus === "ACTIVE") {
        statusMessage = "Activate"
    } else if (appStatus === "INACTIVE") {
        statusMessage = "Inactivate"
    }

    if (isModalAppVisible) {
        appModal = (
            <EuiConfirmModal
                title={`${statusMessage} Partner`}
                onCancel={closeAppModal}
                onConfirm={() => { }}
                cancelButtonText="Cancel"
                confirmButtonText={statusMessage}
                buttonColor={statusMessage === "Suspend" && 'danger'}
                isLoading={loading}
            >
                <p>Are you sure you want to {statusMessage.toLocaleLowerCase()} this partner?</p>
            </EuiConfirmModal>
        );
    }

    return (
        <>
            <EuiPageTemplate
                style={{ paddingTop: '10vh' }}
                template="default"
                pageSideBar={<SideNav />}
                paddingSize="m"
                minHeight={'100vh'}
                pageSideBarProps={{
                    position: 'sticky'
                }}
                bottomBar={showBottomBar && 'Bottom bar'}
                pageHeader={{
                    rightSideItems: [],
                    pageTitle: <>
                        <div style={{ fontSize: '13px' }}>
                            <Link onClick={() => navigate(-1)}>
                                <EuiIcon type={'arrowLeft'}></EuiIcon>{' '}
                                Go Back
                            </Link>
                        </div>
                        {
                            titleRoutes[location.pathname.split('/wallet/')[1]]
                        }
                    </>
                }}
            >

                {modal}
                {destroyModal}
                {appModal}
                <Outlet></Outlet>
            </EuiPageTemplate>
        </>
    );
};

export default WalletPage;

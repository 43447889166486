import { EuiBadge, EuiHealth } from "@elastic/eui";

const optionsLevel = [
  {
    value: "All",
    inputDisplay: <EuiBadge color="hollow">All LEVELS</EuiBadge>,
  },
  {
    value: "LEVEL1",
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: "inherit" }}>
        LEVEL 1
      </EuiHealth>
    ),
  },
  {
    value: "LEVEL2",
    inputDisplay: (
      <EuiHealth color="warning" style={{ lineHeight: "inherit" }}>
        LEVEL 2
      </EuiHealth>
    ),
  },
];

const optionsStatus = [
  {
    value: "All",
    inputDisplay: <EuiBadge color="hollow">All Status</EuiBadge>,
  },
  {
    value: "NOT_VERIFIED",
    inputDisplay: <EuiBadge color="warning">Pending</EuiBadge>,
  },
  {
    value: "VERIFIED",
    inputDisplay: <EuiBadge color="success">Verified</EuiBadge>,
  },
  {
    value: "REJECTED",
    inputDisplay: <EuiBadge color="danger">Rejected</EuiBadge>,
  },
];

const optionsType = [
  {
    value: "All",
    inputDisplay: <EuiBadge color="hollow">ALL Types</EuiBadge>,
  },
  {
    value: "PAYMENTS_COLLECTION",
    inputDisplay: <EuiBadge color="primary">PAYMENTS_COLLECTION</EuiBadge>,
  },
  {
    value: "LINKS",
    inputDisplay: <EuiBadge color="success">LINKS</EuiBadge>,
  },
];

const optionsValidateLevel = [
  {
    value: "LEVEL1",
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: "inherit" }}>
        LEVEL 1
      </EuiHealth>
    ),
  },
  {
    value: "LEVEL2",
    inputDisplay: (
      <EuiHealth color="subdued" style={{ lineHeight: "inherit" }}>
        LEVEL 2
      </EuiHealth>
    ),
  },
  {
    value: "COMPLETED",
    inputDisplay: <EuiHealth color="success">COMPLETED</EuiHealth>,
  },
];

export { optionsLevel, optionsStatus, optionsType, optionsValidateLevel };

import axios from "../config/axios-config"

export default {
    getAllKycs(query) {
        let { status, application, level, limit, page, application_id, q } = query

        let url = 'kycs?'

        if (status) {
            url = `${url}&status=${status}`
        }

        if (application) {
            url = `${url}&application=${application}`
        }

        if (application_id) {
            url = `${url}&application_id=${application_id}`
        }
        
        if (q) {
            url = `${url}&q=${q}`
        }

        if (level) {
            url = `${url}&level=${level}`
        }
        if (limit) {
            url = `${url}&limit=${limit}`
        }

        if (page) {
            page = page + 1
            url = `${url}&page=${page}`
        } else {
            url = `${url}&page=${1}`
        }

        return axios.get(url).then(res => res)
    },

    getSingleKyc(kycId) {
        return axios.get(`/kycs/${kycId}`).then((res) => res)
    },
    addKyc(data) {
        return axios.post(`/kycs/submit-kyc-and-docs`, data).then((res) => res)
    },
    editKyc(kycId, data) {
        return axios.patch(`/kycs/${kycId}/kyc-docs`, data).then((res) => res)
    },
    getSingleMerchant(userId) {
        return axios.get(`/merchants/${userId}`).then((res) => res)
    },
    validateKyc(kyc_id,data) {
        return axios.put(`/kycs/${kyc_id}/validate`, data).then((res) => res)
    },
    rejectKyc(kyc_id,data) {
        return axios.put(`/kycs/${kyc_id}/reject`, data).then((res) => res)
    },
    getKycStatus() {
        return axios.get(`/kycs/stats`).then((res) => res)
    }, 
    getKycHistory(kyc_id) {
        return axios.get(`/kycs/${kyc_id}/history`).then((res) => res)
    },
    getValidations(kyc_id, app_id) {
        return axios.get(`/kycs/${kyc_id}/validation/${app_id}`).then((res) => res)
    }
}

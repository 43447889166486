import React from 'react';
import {
  EuiCollapsibleNavGroup,
  EuiPinnableListGroup
} from '@elastic/eui';
import { useLocation, useNavigate } from 'react-router-dom';



const SideNav = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const LinksPaymentPortal = [
    {
      label: "Kycs",
      pinnable: false,
      className: "pnter-select",
      isActive: location.pathname.indexOf("kycs") > 0,
      onClick: () => {
        navigate("/wallet/kycs");
      },
    },
    // {
    //   label: "Apis",
    //   pinnable: false,
    //   className: "pnter-select",
    //   isActive: location.pathname.indexOf("apis") > 0,
    //   onClick: () => {
    //     navigate("/wallet/apis");
    //   },
    // }
  ];


  return (
    <>
      <EuiCollapsibleNavGroup
        title="Wallet"
        iconType="payment"
        isCollapsible={false}
        initialIsOpen={true}
        style={{ position: 'fixed', width: '13%', minWidth: '150px', top: 100 }}
      >
        <EuiPinnableListGroup
          listItems={LinksPaymentPortal}
          onPinClick={() => {

          }}
          maxWidth={'80%'}
          color="subdued"
          gutterSize="none"
          size="s"
        />
      </EuiCollapsibleNavGroup>
    </>
  );
};


export default SideNav;
import React, { useState, useRef, useEffect } from "react";
import {
	EuiLink,
	EuiInMemoryTable,
	EuiEmptyPrompt,
	EuiSuperSelect,
	EuiIcon,
	EuiSpacer,
	EuiTablePagination,
	EuiText,
	EuiFlexItem,
	EuiFieldSearch,
	EuiFlexGroup,
	EuiTitle,
} from "@elastic/eui";

import KycApi from "../../../../services/kyc";

import { optionsLevel, optionsStatus, optionsType } from "./filters.js";
import { columns } from "./columns";
import { useParams } from "react-router-dom";
import { errorHandler } from "../../../../utils/tools";

const KycList = () => {
	const [reFetch, setReFetch] = useState(1);
	const [isKycModalVisible, setIsKycModalVisible] = useState(false);
	const [message, setMessage] = useState(
		<EuiEmptyPrompt
			title={<h3>No Data</h3>}
			titleSize="xs"
			body={
				<p>
					Looks like you don&rsquo;t have any data.{" "}
					<EuiLink onClick={() => setReFetch((prev) => prev + 1)}>
						Refresh
					</EuiLink>
				</p>
			}
		/>
	);

	// pagination

	const [activePage, setActivePage] = useState(0);
	const [totalEntries, setTotalEntries] = useState(0);
	const [rowSize, setRowSize] = useState(10);
	const [pageCount, setPageCount] = useState(0);

	const [search, setSearch] = useState("");

	const goToPage = (pageNumber) => setActivePage(pageNumber);
	const changeItemsPerPage = (pageSize) => {
		setPageCount(Math.ceil(totalEntries / pageSize));
		setRowSize(pageSize);
		setActivePage(0);
	};

	const handleSearch = (e) => {
		setSearch(e.target.value);
	};

	//

	const tableRef = useRef();
	const { app_id } = useParams();

	const [kycStatusValue, setKycStatusValue] = useState(optionsLevel[0].value);
	const [appTypeValue, setAppTypeValue] = useState(optionsLevel[0].value);
	const [kycLevelValue, setKycLevelValue] = useState(optionsLevel[0].value);

	const handleType = (value) => {
		setAppTypeValue(value);
	};
	const handleStatus = (value) => {
		console.log(value)
		setKycStatusValue(value);
	};

	const clearFilter = () => {
		setKycLevelValue(optionsLevel[0].value);
		setAppTypeValue(optionsLevel[0].value);
		setKycStatusValue(optionsLevel[0].value);
		setSearch("");
	};

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const getAllKycs = () => {
		let query = {
			application: appTypeValue === "All" ? "" : appTypeValue,
			level: kycLevelValue === "All" ? "" : kycLevelValue,
			status: kycStatusValue === "All" ? "" : kycStatusValue,
			limit: rowSize,
			page: activePage,
			q: search,
			application_id: app_id ? app_id : "",
		};

		setIsLoading(true);
		KycApi.getAllKycs(query)
			.then((res) => {
				setIsLoading(false);
				setData(res.data.data);
				setTotalEntries(res?.data?.meta?.totalItems);
				setPageCount(res?.data?.meta?.totalPages);
			})
			.catch((err) => {
				setIsLoading(false);
				errorHandler(err)

			});
	};

	useEffect(() => {
		localStorage.removeItem("email");
		getAllKycs();
	}, [
		kycStatusValue,
		appTypeValue,
		kycLevelValue,
		rowSize,
		activePage,
		reFetch,
		search,
	]);

	return (
		<>

			<EuiFlexGroup>
				{/* <EuiFlexItem grow={3}>
					<EuiFieldSearch
						fullWidth={true}
						placeholder="Search by application"
						isClearable={true}
						onChange={handleSearch}
						value={search}
						aria-label="Search here"
					/>
				</EuiFlexItem> */}

				<EuiFlexItem>
					<EuiSuperSelect
						options={optionsStatus}
						valueOfSelected={kycStatusValue}
						onChange={handleStatus}
					/>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiSuperSelect
						options={optionsType}
						valueOfSelected={appTypeValue}
						onChange={handleType}
					/>
				</EuiFlexItem>
			</EuiFlexGroup>

			<EuiSpacer size="s" />

			<div style={{ display: "flex" }}>
				<EuiText size="xs" color={"gray"}>
					Total KYCs: {data.length} |
				</EuiText>

				<EuiText
					onClick={clearFilter}
					size="xs"
					style={{
						marginLeft: "10px",
						color: "#005b8f",
						backgroundColor: "transparent",
						cursor: "pointer",
					}}
				>
					<EuiIcon type="refresh"></EuiIcon> Refresh
				</EuiText>
			</div>
			<EuiSpacer size="s" />

			{/* <EuiInMemoryTable
				tableCaption="Table Kyc"
				ref={tableRef}
				items={data}
				itemId="id"
				loading={isLoading}
				message={message}
				columns={columns}
				pagination={false}
				sorting={true}
				isSelectable={true}
			/>
			<EuiSpacer size="l" />
			<EuiTablePagination
				aria-label="Table pagination example"
				pageCount={pageCount}
				activePage={activePage}
				onChangePage={goToPage}
				itemsPerPage={rowSize}
				onChangeItemsPerPage={changeItemsPerPage}
				itemsPerPageOptions={[5, 10]}
			/> */}
		</>
	);
};

export default KycList;
